@import '../../submodules/src/styles/flex-grid';
@import '../../submodules/src/styles/input-group';
@import '_variables';

#footer {
  margin-top: 0;
}

.bg-404 {
  background-image: url('@{cdn}/images/site/novo_layout_chico/img-404.png?auto=compress,format&q=65');
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  height: 100vh;
  margin-bottom: 40px;

  padding: 9rem 0 0 15rem;

  @media (@max-screen-md) {
    padding: 16rem 0 0 2rem;
  }
}


.search-404 {
  background-color: rgba(255, 255, 255, 0.6) !important;

  &:focus {
    background-color: @color-white;
  }
}

.card-404 {
  transition: background-color @transition-default;

  &:hover {
    text-decoration: none;
    background-color: @color-primary-1;
  }

  svg {
    fill: @color-primary-5;
  }
}
